/*!

 =========================================================
 * Material Dashboard React - v1.6.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
@import "~react-image-gallery/styles/css/image-gallery.css";

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: #eeeeee;
  color: #3c4858;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}


small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

body {
  background-color: #eeeeee;
  color: #3c4858;
}

blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

a {
  color: #9c27b0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #89229b;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}
/*.fixed-plugin {*/
/*  font-family: "Roboto", "Helvetica", "Arial", sans-serif;*/
/*  font-weight: 300;*/
/*  line-height: 1.5em;*/
/*  position: fixed;*/
/*  top: 180px;*/
/*  right: 0;*/
/*  width: 64px;*/
/*  background: rgba(0, 0, 0, 0.3);*/
/*  z-index: 1031;*/
/*  border-radius: 8px 0 0 8px;*/
/*  text-align: center;*/
/*  top: 120px;*/
/*  .badge-primary-background-color: #9c27b0;*/
/*}*/

/*.fixed-plugin .SocialMediaShareButton,*/
/*.fixed-plugin .github-btn {*/
/*  display: inline-block;*/
/*}*/

/*.fixed-plugin li > a,*/
/*.fixed-plugin .badge {*/
/*  transition: all 0.34s;*/
/*  -webkit-transition: all 0.34s;*/
/*  -moz-transition: all 0.34s;*/
/*  text-decoration: none;*/
/*}*/

/*.fixed-plugin .fa-cog {*/
/*  color: #ffffff;*/
/*  padding: 10px;*/
/*  border-radius: 0 0 6px 6px;*/
/*  width: auto;*/
/*}*/

/*.fixed-plugin .dropdown-menu {*/
/*  right: 80px;*/
/*  left: auto;*/
/*  width: 290px;*/
/*  border-radius: 0.1875rem;*/
/*  padding: 0 10px;*/
/*  position: absolute;*/
/*  color: rgba(0, 0, 0, 0.87);*/
/*  display: inline-block;*/
/*  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);*/
/*  background: #fff;*/
/*  border-radius: 3px;*/
/*}*/

/*.fixed-plugin .fa-circle-thin {*/
/*  color: #ffffff;*/
/*}*/

/*.fixed-plugin .active .fa-circle-thin {*/
/*  color: #00bbff;*/
/*}*/

/*.fixed-plugin .dropdown-menu > .active > a,*/
/*.fixed-plugin .dropdown-menu > .active > a:hover,*/
/*.fixed-plugin .dropdown-menu > .active > a:focus {*/
/*  color: #777777;*/
/*  text-align: center;*/
/*}*/

/*.fixed-plugin img {*/
/*  border-radius: 0;*/
/*  width: 100%;*/
/*  height: 100px;*/
/*  margin: 0 auto;*/
/*}*/

/*.fixed-plugin .dropdown-menu li > a:hover,*/
/*.fixed-plugin .dropdown-menu li > a:focus {*/
/*  box-shadow: none;*/
/*}*/
/*.fixed-plugin .badge {*/
/*  border: 3px solid #ffffff;*/
/*  border-radius: 50%;*/
/*  cursor: pointer;*/
/*  display: inline-block;*/
/*  height: 23px;*/
/*  margin-right: 5px;*/
/*  position: relative;*/
/*  width: 23px;*/
/*  background-color: rgba(30, 30, 30, 0.97);*/
/*}*/

/*.fixed-plugin .badge.active,*/
/*.fixed-plugin .badge:hover {*/
/*  border-color: #00bbff;*/
/*}*/

/*.fixed-plugin .badge-purple {*/
/*  background-color: #9c27b0;*/
/*}*/

/*.fixed-plugin .badge-blue {*/
/*  background-color: #00bcd4;*/
/*}*/

/*.fixed-plugin .badge-green {*/
/*  background-color: #4caf50;*/
/*}*/

/*.fixed-plugin .badge-orange {*/
/*  background-color: #ff9800;*/
/*}*/

/*.fixed-plugin .badge-red {*/
/*  background-color: #f44336;*/
/*}*/

/*.fixed-plugin h5 {*/
/*  font-size: 14px;*/
/*  margin: 10px;*/
/*}*/
/*.fixed-plugin .dropdown-menu li {*/
/*  display: block;*/
/*  padding: 4px 0px;*/
/*  width: 25%;*/
/*  float: left;*/
/*}*/

/*.fixed-plugin li.adjustments-line,*/
/*.fixed-plugin li.header-title,*/
/*.fixed-plugin li.button-container {*/
/*  width: 100%;*/
/*  height: 50px;*/
/*  min-height: inherit;*/
/*  padding: 0px;*/
/*  text-align: center;*/
/*}*/

/*.fixed-plugin li.adjustments-line p {*/
/*  margin: 0;*/
/*}*/

/*.fixed-plugin li.adjustments-line div,*/
/*.fixed-plugin li.header-title div,*/
/*.fixed-plugin li.button-container div {*/
/*  margin-bottom: 5px;*/
/*}*/
/*.fixed-plugin li.header-title {*/
/*  height: 30px;*/
/*  line-height: 25px;*/
/*  font-size: 12px;*/
/*  font-weight: 600;*/
/*  text-align: center;*/
/*  text-transform: uppercase;*/
/*}*/

/*.fixed-plugin .adjustments-line p {*/
/*  float: left;*/
/*  display: inline-block;*/
/*  margin-bottom: 0;*/
/*  font-size: 1em;*/
/*  color: #3c4858;*/
/*}*/

/*.fixed-plugin .adjustments-line a {*/
/*  color: transparent;*/
/*}*/

/*.fixed-plugin .adjustments-line a .badge-colors {*/
/*  position: relative;*/
/*  top: -2px;*/
/*}*/

/*.fixed-plugin .adjustments-line a a:hover,*/
/*.fixed-plugin .adjustments-line a a:focus {*/
/*  color: transparent;*/
/*}*/
/*.fixed-plugin .adjustments-line .dropdown-menu > li.adjustments-line > a {*/
/*  padding-right: 0;*/
/*  padding-left: 0;*/
/*  border-bottom: 1px solid #ddd;*/
/*  border-radius: 0;*/
/*  margin: 0;*/
/*}*/

/*.fixed-plugin .dropdown-menu > li > a.img-holder {*/
/*  font-size: 16px;*/
/*  text-align: center;*/
/*  border-radius: 10px;*/
/*  background-color: #fff;*/
/*  border: 3px solid #fff;*/
/*  padding-left: 0;*/
/*  padding-right: 0;*/
/*  opacity: 1;*/
/*  cursor: pointer;*/
/*  display: block;*/
/*  max-height: 100px;*/
/*  overflow: hidden;*/
/*  padding: 0;*/
/*}*/

/*.fixed-plugin .dropdown-menu > li > a.img-holder img {*/
/*  margin-top: auto;*/
/*}*/
/*.fixed-plugin .dropdown-menu > li:hover > a.img-holder,*/
/*.fixed-plugin .dropdown-menu > li:focus > a.img-holder {*/
/*  border-color: rgba(0, 187, 255, 0.53);*/
/*}*/

/*.fixed-plugin .dropdown-menu > .active > a.img-holder,*/
/*.fixed-plugin .dropdown-menu > .active > a.img-holder {*/
/*  border-color: #00bbff;*/
/*  background-color: #ffffff;*/
/*}*/
/*.fixed-plugin .dropdown .dropdown-menu {*/
/*  -webkit-transform: translateY(-15%);*/
/*  -moz-transform: translateY(-15%);*/
/*  -o-transform: translateY(-15%);*/
/*  -ms-transform: translateY(-15%);*/
/*  transform: translateY(-15%);*/
/*  top: 27px;*/
/*  opacity: 0;*/
/*  transform-origin: 0 0;*/
/*  display: none;*/
/*}*/

/*.fixed-plugin .dropdown .dropdown-menu:before {*/
/*  border-bottom: 0.4em solid transparent;*/
/*  border-left: 0.4em solid rgba(0, 0, 0, 0.2);*/
/*  border-top: 0.4em solid transparent;*/
/*  right: -16px;*/
/*  top: 46px;*/
/*}*/
/*.fixed-plugin .dropdown .dropdown-menu:after {*/
/*  border-bottom: 0.4em solid transparent;*/
/*  border-left: 0.4em solid #ffffff;*/
/*  border-top: 0.4em solid transparent;*/
/*  right: -16px;*/
/*}*/

/*.fixed-plugin .dropdown .dropdown-menu:before,*/
/*.fixed-plugin .dropdown .dropdown-menu:after {*/
/*  content: "";*/
/*  display: inline-block;*/
/*  position: absolute;*/
/*  top: 46px;*/
/*  width: 16px;*/
/*  transform: translateY(-50%);*/
/*  -webkit-transform: translateY(-50%);*/
/*  -moz-transform: translateY(-50%);*/
/*}*/

/*.fixed-plugin .dropdown.show .dropdown-menu {*/
/*  display: block;*/
/*  visibility: visible;*/
/*  opacity: 1;*/
/*  -webkit-transform: translateY(-13%);*/
/*  -moz-transform: translateY(-13%);*/
/*  -o-transform: translateY(-13%);*/
/*  -ms-transform: translateY(-13%);*/
/*  transform: translateY(-13%);*/
/*  transform-origin: 0 0;*/
/*}*/
/*.fixed-plugin.rtl-fixed-plugin {*/
/*  right: auto;*/
/*  left: 0px;*/
/*  border-radius: 0 8px 8px 0;*/
/*}*/
/*.fixed-plugin.rtl-fixed-plugin .dropdown-menu {*/
/*  right: auto;*/
/*  left: 80px;*/
/*}*/
.img-photo {
  width: 100%;
  height: 80%;
}
.thumbnail-photo {
  width: 40px;
  heigh: 40px;
}
.gallery-width {
  height: 80%;
}

