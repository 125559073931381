body {

}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.small-text {
  font-size: 10px;
}
.page-center {
  width: 50%;
  padding-top: 100px;
}
.container-md-writing {
  position: relative;
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  background-color: hsl(0, 2, 98);
  background-color: lightgray;
  background-repeat: repeat;
  background-size:cover;
}
.container-md {
  position: relative;
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  background:url("./images/bamboo-plants.jpg");
  background-color: hsl(0, 2, 98);
  background-color: lightgray;
  background-repeat: repeat;
  background-size:cover;
}
.by-line {
  text-align: center;
  font-style: italic;
  font-size: 10px;
}
.author-line {
  text-align: left;
  font-style: italic;
  font-size: 18px;
  padding-top: 0px;
}
.demo-block {
  border-top: solid 1px #dcdcdc;
  border-bottom: solid 1px #dcdcdc;
}

.flip-book {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  display: none;
  z-index: 100;
}

.html-book {
  background-size: cover;
}

.page {

  background-color: hsl(35, 55, 98);
  color: hsl(0, 100, 6);
  border: solid 1px hsl(35, 20, 70);

  overflow: hidden;

  .page-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    .page-title {
      height: 40px;
      font-size: 120%;
      font-weight: bold;
      text-transform: uppercase;
      text-align: left;
      padding-left:40px;
      padding-top: 20px;
    }
    .page-header {
      height: 20px;
      font-size: 70%;
      font-style: italic;
      text-transform: uppercase;
      text-align: center;
    }

    .page-image {
      //height: 100%;
      //background-size: contain;
      //background-position: center center;
      //background-repeat: no-repeat;
      display:block;
      margin-left: auto;
      margin-right: auto;
      width: 50px;
      height: 50px;
    }
    .page-text {
      height: 100%;
      flex-grow: 1;
      font-size: 90%;
      text-align: justify;
      margin-top: 10px;
      padding-top: 10px;
      padding-right:10px;
      padding-left:40px;
      box-sizing: border-box;
      //border-top: solid 2px hsl(35, 55, 90);
    }

    .page-footer {
      height: 30px;
      border-top: solid 1px hsl(35, 55, 90);
      font-size: 80%;
      color: hsl(35, 20, 50);
      padding-right:10px;
      padding-left:10px;
    }
  }

  &.--left {
    border-right: 0;
    box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
  }

  &.--right {
    border-left: 0;
    box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);

    .page-footer {
      text-align: right;
    }
  }

  &.hard {
    background-color: hsl(35, 50, 90);
    border: solid 1px hsl(35, 20, 50);
  }
  &.page-cover-writing {
    background-image: url('./images/orchid.jpg') !important;
  }
  &.page-cover-writing-end {
    background-image: url('./images/cloud.jpg') !important;
  }

  &.page-cover {
    //background-color: hsl(35, 45, 80);
    color:  hsl(35, 35, 35);
    background-image: url('./images/cover.jpg');
    background-size:cover;

    //border: solid -10px hsl(35, 20, 50);


    h2 {
      text-align: center;
      padding-top: 40%;
      font-size: 250%;
      font-weight: bold;
      font-family:cursive;
    }
    h3 {
      text-align: center;
      font-size: 100%;
      font-weight: bold;
      padding-bottom: 70%;
    }

    &.page-cover-top {
      box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4);
    }

    &.page-cover-bottom {
      box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), 10px 0 8px 0px rgba(0, 0, 0, 0.4);
    }
  }

}
